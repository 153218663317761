import axios from 'axios';

import { localStorageGetToken, getClientLocale, localStorageIsTwa } from "./utils/functions/local-storage";
import { VERSION_CWA_NUMBER } from "./utils/constants";
import { isSaripay } from "./utils/functions/check-saripondo";
import { getSubdomain } from "./utils/functions/get-subdomain";

// setup base url for different environment
axios.defaults.baseURL = process.env.REACT_APP_CLIENT_API_BASE_URL;
axios.defaults.headers.common[ 'x-app-type' ] = localStorageIsTwa() ? "twa" : "cwa";
axios.defaults.headers.common[ 'x-source-platform' ] = isSaripay() ? "saripay" : "mykuya"
axios.defaults.headers.common["x-shop-url"] = getSubdomain(window.location.host);
axios.defaults.headers.common["x-app-version"] = VERSION_CWA_NUMBER;
axios.defaults.headers.common["x-locale"] = getClientLocale() ? getClientLocale() : "en";

axios.interceptors.request.use(function (config) {
    let token = getToken();
    if (token) {
        config.headers.Authorization = getToken();
    }
    return config;
});



export const GraphRequest = {
    all: (obj) => axios.post("/", obj),
}

function getToken() {
    let token = '';
    token = localStorageGetToken();
    return token;
}

export const networkCall = async (requestBody) => {
    try {
        const { data } = await GraphRequest.all(requestBody);
        return data;
    
    } catch(err) {}
}

export default axios;