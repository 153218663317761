import React from 'react';

import NotSupportedImg from "../../assests/images/welcome/not-supported-saripondo.svg";
import ChromeLogo from "../../assests/images/welcome/chrome-logo.png";
import MyMallLogo from "../../assests/icons/mymall-small.png";

import { isIOS } from "../../utils/functions/check-browser";

function BrowserNotSupported() {
    return (
		<div className="browser-not-supported-container">
			<h1>Experience our best service</h1>
			<h2>This browser may have some limitations. There is a better way!</h2>

			<div className="browser-not-supported-image">
				<img src={NotSupportedImg} alt="NotSupportedImg" />
			</div>

			{/*<div className="card card-blue" onClick={() => window.location.replace("https://onelink.to/MyKuyaApp")}>*/}
			{/*	<div className="card-image">*/}
			{/*		<img src={MyMallLogo} alt="logo" />*/}
			{/*	</div>*/}
			{/*	<div className="card-content">*/}
			{/*		<h5>Get saripay app</h5>*/}
			{/*		<h6>The best way to experience saripay</h6>*/}
			{/*		<h4 className="link">Download Now</h4>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{!isIOS() ? (
				<div className="card">
					<div className="card-image">
						<img src={ChromeLogo} alt="logo" />
					</div>
					<div className="card-content">
						<h5>Use Chrome</h5>
						<h6>No downloads needed. Access SariPondo web using Chrome.</h6>
					</div>
				</div>
			) : null}
		</div>
	);
}

export default BrowserNotSupported