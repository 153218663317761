import React from 'react'
import { useQRCode } from 'next-qrcode';
import { jsPDF } from "jspdf";
import saripayIcon from "../../assests/icons/saripondo-black.png"

export const QRCode = (props) => {
	const { Canvas } = useQRCode();

	const onImageDownload = () =>  {
		const doc = new jsPDF();
		doc.html(document.getElementById('QRContent'), {
			callback: (doc) => { doc.save('QRCode.pdf'); },
			margin: [10, 10, 10, 10],
			autoPaging: 'text',
			x: 15,
			y: 15,
			width: 170,
			windowWidth: 400
		});
	}

	return (
		<>
			<div id="QRContent">
				<img src={saripayIcon} />
				<Canvas
					text={JSON.stringify({
						clientId: props.client?.id?.toString()
					})}
					options={{
						errorCorrectionLevel: 'L',
						margin: 2,
						scale: 5,
						width: 275,
					}}
				/>
				<div className="QR-text">
					<h2>{props.client?.givenName} {props.client?.lastName}</h2>
					<h3>Top-up with Authorized Partners</h3>
					<p><span>&#8226;</span> App Receipt is available after payment</p>
					<p><span>&#8226;</span> You'll also get an email confirmation</p>
				</div>
			</div>
			<a className="download-qr-code-btn" onClick={onImageDownload}>Download QR Code</a>
		</>
	);
}