import React, { Component } from "react";
import { connect } from "react-redux";

import * as LOG_EVENT from "../../../utils/analytics/index";

import saripayLogo from "../../../assests/icons/saripondo-logo-green.svg";

import FemaleShoppingImg from "../../../assests/images/welcome/female-shopping.png";

import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";
import SelectCity from "../SelectCity";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";

import { mapGetCurrentLocationWithoutMap } from "../../../utils/redux/actions/mapActions";
import { toggleSelectCityModal } from "../../../utils/redux/actions/registerActions/registerChangeState";
import { saveLastPathName } from "../../../utils/redux/actions/commonActions/commonChangeState";
import {localStorageIsTwa} from "../../../utils/functions/local-storage"

class Welcome extends Component {
	componentDidMount() {
		window.addEventListener("load", this.handleLoad);
		this.onGetCurrent();
	}
	handleLoad = () => {
		let checkTimeout = setTimeout(() => {
			LOG_EVENT.logEvent(LOG_EVENT.WELCOME_PAGE_LOADED, {
				os: localStorageIsTwa() ? "twa" : "web",
				autoGenerated: false,
			});
			clearTimeout(checkTimeout);
		}, 1000);
		this.props.dispatch(saveLastPathName("/welcome"));
	};

	onGetCurrent = (e) => {
		LOG_EVENT.logEvent(LOG_EVENT.TRIED_GIVE_LOCATION__ACCESS, { location: "locationPermission", os: localStorageIsTwa() ? "twa" : "web" });
		this.props.dispatch(mapGetCurrentLocationWithoutMap());
	};

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};

		return (
			<>
				<div className="welcome-container">
					<div className="logo-container">
						<img src={saripayLogo} alt="SariPondo logo" />
					</div>
					<h1>Welcome to SariPondo</h1>
					<h2>Empowering Communities</h2>
					{/* <h2>In one go. In Minutes.</h2> */}
					<div className="welcome-call-to-actions">
						<div className="shopping-image">
							<img src={FemaleShoppingImg} alt="FemaleShoppingImg" />
						</div>
						<h5>Where are you?</h5>
						<div className="welcome-btn">
							<CustomButton
								onClick={this.onGetCurrent}
								type="button"
								title={
									this.props.map.gettingLocationWithoutMapLoading ? (
										<div className="next-button-loading">
											<WhiteLoading />
										</div>
									) : (
										"Allow Location Access"
									)
								}
								disabled={this.props.map.userDeniedGeolocationAccess}
								variant="contained"
							/>
						</div>
						<div className="horizontal-border">
							<h6>
								<span>OR</span>
							</h6>
						</div>
						<div className="welcome-btn">
							<CustomButton
								onClick={() => {
									LOG_EVENT.logEvent(LOG_EVENT.TRIED_GIVE_LOCATION__ACCESS, {
										location: "cityChosen",
										os: localStorageIsTwa() ? "twa" : "web",
									});
									this.props.dispatch(toggleSelectCityModal());
								}}
								type="button"
								title="Select Manually"
								variant="outlined"
								outline
							/>
						</div>
					</div>
					{this.props.welcome.toggleSelectCityModal ? (
						<BottomSheet
							isOpen={this.props.welcome.toggleSelectCityModal}
							classes="select-city-bottom-sheet"
							styles={bottomSheetStyle}
							config={{ marginTop: 50 }}
							indicator={true}
							onChangeOverlay={(isOpen) => {
								if (!isOpen) {
									this.props.dispatch(toggleSelectCityModal());
								}
							}}
						>
							<SelectCity />
						</BottomSheet>
					) : null}
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	map: state.map,
	welcome: state.register,
});
export default connect(mapStateToProps)(Welcome);
