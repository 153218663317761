import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import { push } from "connected-react-router";

import SahreingSvg from '../../../assests/images/account/sharing-is-caring.svg';
import {shareCode} from '../../../utils/functions/share-code';
import TitleBar from '../../../organisms/title-bar';
import backButton from '../../../v5-icons/back-button.svg';
import chatSupport from '../../../v5-icons/chat-support.svg';
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";

import CustomButton from '../../ui-elements/CustomButton/CustomButton';
import { isSaripay } from "../../../utils/functions/check-saripondo";
class Refer extends Component {

    onBack = () => {
        this.props.history.goBack();
    }
    
    onSupport = () => {
        this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
    }
    
    render() {
        return (
			<Fragment>
				<div className="refer Refer">
					<TitleBar
						title="Invite Friends"
						leftIcon={backButton}
						rightIcon={chatSupport}
						handleClickLeftIcon={this.onBack}
						handleClickRightIcon={this.onSupport}
					/>
					<div className="refer-img">
						<img src={SahreingSvg} alt="" />
					</div>
					<div className="refer-description">
						<h6>Sharing is Caring</h6>
						<p className="">Bring your friends to { isSaripay() ? "SariPondo" : "mykuya" } with this code.</p>
					</div>
					<div className="refer-action-container">
						<input type="text" className="refer-input-code" defaultValue={this.props.common.client.referralCode} disabled />
					</div>
					<div className="share-code">
						<CustomButton
							title="Share Code"
							type="button"
							className="primary-button"
							onClick={(e) => shareCode(e, this.props.common.client.referralCode)}
						/>
					</div>
				</div>
			</Fragment>
		);
    }
}

const mapStateToProps = state => ({
    common: state.common,
});

export default connect(mapStateToProps)(Refer);