import React, { Fragment } from "react";
import { connect } from "react-redux";
import LocationBox from "../LocationBox/LocationBox";

import saripayIcon from "../../../assests/icons/saripondo-logo-black.svg";

function HomeHeader({...props}) {
	
	return (
		<Fragment>
			<div className="Home_Header">
				<div className="Home_Header_greeting_container">
					<img src={saripayIcon} alt="SariPondo-logo" />
				</div>
				<LocationBox
					navigateToSearchLocation={props.navigateToSearchLocation}
					handleClickOnChatSupport={props.handleClickOnChatSupport}
					locationTitle={props.locationTitle}
					onSearchPage={props.onSearchPage}
				/>
			</div>
		</Fragment>
	);
}

const mapStateToProps = state => ({
	client: state.common.client,
});

export default connect(mapStateToProps)(HomeHeader);