import React, { Component, Fragment, createRef } from "react";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";
import Button from "@material-ui/core/Button";
import ClearBtn from "../../../../assests/icons/clear.svg";

export class HomeSearchInputBox extends Component {
	constructor(props) {
		super(props);
		this.inputSearch = createRef();
		this.onFocusSearch = this.onFocusSearch.bind(this);
		this.onBlurSearch = this.onBlurSearch.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.state = {
			value: this.props.defaultValue,
			focusInput: false,
		};
	}

	onFocusSearch() {
		this.setState({ focusInput: true });
	}

	onBlurSearch() {
		this.setState({ focusInput: false });
		this.inputSearch.value = "";
		this.props.onChangeSearchInput(this.inputSearch.value);
	}

	clearSearch() {
		this.inputSearch.value = "";
		this.props.onChangeSearchInput(this.inputSearch.value);
		this.setState({ focusInput: true});

	}

	render() {
		return (
			<Fragment>
				<div className="Home_Search_Input_Box">
					<div className="Search_Box Search_Box_Focused">
						<div className="form-group Search_Box_Form">
							<DynamicIcon icon="GreenSearch" classes="Search_Product"/>
							<input type="text" className="form-control Search_Placeholder" id="searchInput"
								   placeholder={`${this.state.focusInput ? "" : "What do you need today?"}`}
								   onFocus={this.onFocusSearch}
								   autoComplete="off"
								   onKeyDown={event => this.props.onChangeSearchInput(this.inputSearch.value)}
								   onChange={event => this.props.onChangeSearchInput(this.inputSearch.value)}
								   ref={el => this.inputSearch = el}/>
							<img className={`Cancel_Img ${this.inputSearch.value !== "" ? "Show" : ""}`} src={ClearBtn}
								 alt="" onClick={this.clearSearch}/>

						</div>
					</div>
					{this.inputSearch.value ? <div className="Button_Box Button_Box_Focused">
						<Button type="button" className="H_S_I_B_Cancel_Btn" onClick={this.onBlurSearch}>Cancel</Button>
					</div> : null}
				</div>
			</Fragment>
		);
	}

}

export default HomeSearchInputBox;