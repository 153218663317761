export const getSubdomain = (url) => {
	const subdomain = url.split("saripondo")[0];
	if(subdomain.includes("localhost")) {
		return "app"
	} else if(subdomain.includes(".dev")) {
		return subdomain.split(".dev")[0]
	} else if (subdomain.includes(".stage")) {
		return subdomain.split(".stage")[0]
	} else if (subdomain !== "" && !subdomain.includes("dev.") && !subdomain.includes("stage.")) {
		return subdomain.split(".")[0]
	} else return "app"
}