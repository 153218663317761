import { toast } from 'react-toastify';
import { isSaripay } from "./check-saripondo";

export function shareCode(e, text) {
  let SHARE_TEXT = `Hey, I am inviting you to try ${ isSaripay() ? "SariPondo" : "mykuya" }. Shop in Seconds. Delivered in Minutes! From malls you know & love.\nUse my code ${text} to sign up!\nhttps://mymallph.com`

  // let SHARE_TEXT = `I am inviting you to start saving time by using MyKuya - #YourHelpingHandOnDemand\n\nDownload the MyKuya app and use my code ${text} to sign up!\nWeb Application: https://bit.ly/mykuyawebapp\nGoogle Play: https://goo.gl/9Ee5UU`;
  // let SHARE_TEXT = `Hi friend! I am inviting you to start saving time by using MyKuya for your daily needs #YourHelpingHandOnDemand\n\nSign up and use my code ${text} to get 20% back after your second request.\nTry it now: https://bit.ly/mykuyawebapp`;
  if (window.navigator.share) {
    window.navigator
		.share({
			title: SHARE_TEXT,
			text: SHARE_TEXT,
			// url: "https://mymallph.com",
		})
		.then(() => {
			console.log("Thanks for sharing!");
		})
		.catch((e) => {
			// throw e;
			copyToClipboard(SHARE_TEXT);
			iosCopyToClipboard(SHARE_TEXT);
			showToastCopyToClipboard();
		});
  } else {
    copyToClipboard(SHARE_TEXT);
    iosCopyToClipboard(SHARE_TEXT);
    showToastCopyToClipboard();
    // alert('Your browser does not support');
  }
}

export const showToastCopyToClipboard = () => {
  toast(`Copied to clipboard`, {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
  });
}
/**
 * for android and ios
 * @param {*} str 
 */
const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
/**
 * for and old ios
 * @param {*} str 
 */
function iosCopyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  var oldContentEditable = el.contentEditable,
    oldReadOnly = el.readOnly,
    range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  var s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  document.execCommand('copy');
  document.body.removeChild(el);
}