import React from 'react';
import { isSaripay } from "../../../../utils/functions/check-saripondo";

export default function ThankUsing(props) {

    return (
		<div className="thank-using">
			<p>Thanks for using { isSaripay() ? "SariPondo" : "mykuya" } app! </p>
			<a target="_blank" href="https://mykuya.com" rel="noopener noreferrer" className="website-name">
				www.saripondo.com
			</a>
		</div>
	);
}