import React, {Fragment, Component} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { push } from "connected-react-router";

import AccountProfile from './AccountProfile/AccountProfile';
import AccountLinks from './AccountLink/AccountLinks';
import AccountVersion from './AccountVersion/AccountVersion';
import { navigateToSupport } from "../../utils/redux/actions/supportActions/supportChangeState";
import { fetchClient, appLogout, appGoHome } from "../../utils/redux/actions/commonActions";
import { navigateSavedPlaces } from '../../utils/redux/actions/savedPlacesActions';
import TitleBar from '../../organisms/title-bar';
import backButton from '../../v5-icons/back-button.svg';
import chatSupport from '../../v5-icons/chat-support.svg';
import { setMyJobsLocationPointer } from "../../utils/redux/actions/jobsActions";
import * as LOG_EVENT from "../../utils/analytics/index";
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from "../../utils/constants/urls";
import aboutIcon from "../../assests/icons/account-page/ic_cl_mymall@3x.png";
import whatsNewIcon from "../../assests/icons/account-page/ic_cl_announcements@3x.png";
import privacyIcon from "../../assests/icons/account-page/ic_cl_privacy@3x.png";
import termsIcon from "../../assests/icons/account-page/ic_cl_terms@3x.png";
import paymentIcon from "../../assests/icons/account-page/ic_cl_cards@3x.png";
import savedIcon from "../../assests/icons/account-page/ic_cl_saved@3x.png";
import inviteIcon from "../../assests/icons/account-page/ic_cl_invite@3x.png";
import { localStorageIsTwa } from "../../utils/functions/local-storage";
import { isSaripay } from "../../utils/functions/check-saripondo";

class Account extends Component {

    constructor(props) {
        super(props);

        this.onLogout = this.onLogout.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
    }

    componentDidMount() {
        if(_.isEmpty(this.props.client)) this.props.dispatch(fetchClient());
    }

    onLogout(e) {
        this.props.dispatch(appLogout());
    }

    onNavigate(e, data) {
        if (_.has(data, "exit")) {
			window.open(data.link);
		} else if (data.type === "payment") {
			LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_METHODS_PAGE_CWA, { from: "account" });
			this.props.history.push(data.link);
		} else if (data.type === "invite") {
			LOG_EVENT.logEvent(LOG_EVENT.INVITE_FRIENDS_PAGE_CWA);
			this.props.history.push(data.link);
		} else if (data.type === "savedPlaces") {
			LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACES_PAGE_CWA);
			LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACES_OPENED, { from: "account", os: localStorageIsTwa() ? "twa" : "web" });
			this.props.dispatch(navigateSavedPlaces());
		} else if (data.type === "jobs") {
			LOG_EVENT.logEvent(LOG_EVENT.MY_JOBS_PAGE_CWA, { from: ["tab", "home"] });
			this.props.dispatch(setMyJobsLocationPointer());
			this.props.dispatch(push("/jobs"));
		} else if (data.type === "privacy" || data.type === "terms") {
			window.location.replace(data.link);
		} else if (data.type === "new") {
			LOG_EVENT.logEvent(LOG_EVENT.NEWS_LIST_PAGE, { from: ["account", "feedButton"] });
			this.props.dispatch(push(data.link));
		} else if (data.type === "about") {
			LOG_EVENT.logEvent(LOG_EVENT.ABOUT_PAGE_CWA);
			this.props.dispatch(push(data.link));
		} else {
			this.props.history.push(data.link);
		}
    }

    onSupport = () => {
        this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
    }

    render() {
		let isAuthenticated = this.props.common.auth && this.props.common.authCheck;
		const ACCOUNT_LINKS_COLLECTION = [
			{ title: 'Payment Methods', link: '/account/billing', type: 'payment', icon: paymentIcon },
			{ title: 'Saved Places', link: '', type: 'savedPlaces', icon: savedIcon },
		];

		const sourcePlatform = isSaripay() ? "SariPondo" : "mykuya";
		const UNAUTHORIZED_ACCOUNT_LINKS_COLLECTION = [
			{ title: "What's New", link: "/news", type: "new", icon: whatsNewIcon },
			{ title: "Privacy Policy", link: PRIVACY_POLICY_URL, type: "privacy", icon: privacyIcon },
			{ title: "Terms of Use", link: TERMS_AND_CONDITIONS_URL, type: "terms", icon: termsIcon },
		];
        return (
			<Fragment>
				<div className={`account-container ${this.props.common.logInBottomSheet || this.props.common.logOutBottomSheet ? "z-index" : ""}`}>
					<TitleBar title="Account" handleClickLeftIcon={() => this.props.dispatch(appGoHome())} handleClickRightIcon={this.onSupport} />
					<AccountProfile data={this.props.client} />
					{isAuthenticated && (
						<>
							<AccountLinks onNavigate={this.onNavigate} links={ACCOUNT_LINKS_COLLECTION} />
							<div className="border-wrapper">
								<div className="border-line"></div>
							</div>
						</>
					)}
					<AccountLinks onNavigate={this.onNavigate} links={UNAUTHORIZED_ACCOUNT_LINKS_COLLECTION} />
					<div className="border-wrapper">
						<div className="border-line"></div>
					</div>
					<AccountVersion
						isAuthenticated={isAuthenticated}
						isLogoutBottomSheetOpen={this.props.common.logOutBottomSheet}
						dispatch={this.props.dispatch}
					/>
				</div>
			</Fragment>
		);
    }
}

const mapStateToProps = state => ({
    client: state.common.client,
    common: state.common
});

export default connect(mapStateToProps)(Account);