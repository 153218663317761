import React, { Component } from 'react';

export class ManagementMessage extends Component {
    render() {
        return (
			<>
				<span className="timezone">{this.props.date}</span>
				<div className="rce-mbox rce-mbox-mc">
					<div className="rce-mbox-body">
						<div className="rce-mbox-text">
							<p>{this.props.text}</p>
						</div>
						<div className="rce-mbox-sender-name">
							<span>SariPondo team</span>
						</div>
					</div>
				</div>
			</>
		);
    }
}

export default ManagementMessage;
