import React from "react";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import SearchIcon from "../../../assests/icons/GreenSearch.svg";
import ClearIcon from "../../../v5-icons/clear.svg";
const classNames = require("classnames");

const LocationSearchInput = React.forwardRef((props, ref) => {
	const isKeyboardOpen = useDetectKeyboardOpen();

	React.useEffect(() => {
		props.setParentState(isKeyboardOpen);
	}, [isKeyboardOpen])
	return (
			<div className={classNames("location-search-box", props.classList)}>
				<div className="form-group-container form-group-contain-icon">
					<div className="icon-input-container">
						<img src={SearchIcon} alt="searchIcon" className="icon" />
						<input
							type="text"
							autoComplete={props.autoComplete}
							onKeyUp={props.onSearchPlace}
							className="location-search-box-input"
							placeholder={props.placeholder ? props.placeholder : "Search"}
							onFocus={() => props.onLocationSearchInputFocus()}
							ref={ref}
						/>
					</div>
					{props.isSearching && <img src={ClearIcon} alt="clearIcon" className="icon" onClick={props.onClearQuery} />}
				</div>
				{isKeyboardOpen && (<p onClick={props.onCancelSuggestions}>Cancel</p>)}
			</div>
		)
	}
);

export default LocationSearchInput;
