import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

import { setPromoCode } from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import { isSaripay } from "../../../utils/functions/check-saripondo";

class PromoCodeBottomSheet extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            disableApplyButton: true,
            promoCode: null
        }
        this.promoCodeRef = React.createRef();
    }

    componentDidMount = () => {
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidUpdate = () => {
        if (this.props.isOpenPromoCodeBottomSheet) {
            this.inputSelect();
        }
    }

    inputSelect = () => {
        if (this.promoCodeRef.current) {
            this.promoCodeRef.current.focus();
        }
    };

    handleChange = (e) => {
        if (!this.promoCodeRef.current.value.length) {
            this.setState(() => ({
                disableApplyButton: true,
                promoCode: null
            }));
            this.props.dispatch(setPromoCode(null));
        } else {
            this.setState(() => ({
                promoCode: this.promoCodeRef.current.value,
                disableApplyButton: false,
            }));
            this.props.dispatch(setPromoCode(this.promoCodeRef.current.value));
        }
    }

    handleFocus = () => {
        if (this.promoCodeRef.current) {
            if(this.promoCodeRef.current.value == "") {
                this.setState(() => ({
                    disableApplyButton: true,
                }))
            }
            if (this.props.removePromoCode && !_.isNull(this.promoCodeRef.current)) {
                this.promoCodeRef.current.select();
            } else if (this.props.changePromoCode && !_.isNull(this.promoCodeRef.current)) {
                this.promoCodeRef.current.select();
            }
        }
    }
    render() {
        
        return (
			<Fragment>
				<div className="title">
					<h2>{this.props.mymall ? "Promo Code" : "Discount Code"}</h2>
				</div>
				<div className="promo-code-container">
					<div className="promo-code-form form-group-container focused">
						<label>{this.props.mymall ? "Promo Code" : "Discount Code"}</label>
						<input
							value={this.props.value}
							autoFocus={this.props.autoFocus}
							name="code"
							onFocus={this.handleFocus}
							onChange={this.handleChange}
							placeholder={isSaripay() ? "e.g. SARIPONDO50" : "e.g. MYKUYA50"}
							autoComplete="off"
							ref={this.promoCodeRef}
						/>
					</div>
				</div>
				<div className="promo-code-button-container">
					{this.props.removePromoCode ? (
						<Button className="cancel-button" onClick={this.props.handleRemovePromoCode}>
							Remove
						</Button>
					) : (
						<Button className="cancel-button" onClick={this.props.handleCancelPromoCode}>
							Cancel
						</Button>
					)}

					<Button className="apply-code-button" disabled={this.state.disableApplyButton} onClick={this.props.applyCode}>
						{this.props.loading ? (
							<div className="next-button-loading">
								<WhiteLoading />
							</div>
						) : (
							"Apply Code"
						)}
					</Button>
				</div>
			</Fragment>
		);
    }
}

const mapStateToProps = (state) => ({
    ...state.booking
});

export default connect(mapStateToProps)(PromoCodeBottomSheet);