import React, { Component, createRef } from "react";
import _ from "lodash";

import CustomButton from "../../../ui-elements/CustomButton/CustomButton";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";
import ClearBtn from "../../../../assests/icons/clear.svg";

export class SelectCitySearchInput extends Component {
	constructor(props) {
		super(props);
		this.inputSearch = createRef();
		this.state = {
			value: this.props.defaultValue,
		};
	}

	onBlurSearch = () => {
		this.inputSearch.value = "";
		this.props.onChangeSearchInput(this.inputSearch.value);
	}

	clearSearch = () => {
		this.inputSearch.value = "";
		this.props.onChangeSearchInput(this.inputSearch.value);
	}

	render() {
		return (
			<div className="select-city-search-input-container">
				<div className="form-group search-input-form">
					<DynamicIcon icon="GreenSearch" classes="search-icon"/>
					<input type="text" className="form-control search-input"
							placeholder="Search your city"
							autoComplete="off"
							onChange={() => this.props.onChangeSearchInput(this.inputSearch.value)}
							ref={el => this.inputSearch = el}/>
					{!_.isNull(this.inputSearch.current) && this.inputSearch.value !== "" ? 
						<img className="clear-icon" src={ClearBtn}
								alt="" onClick={this.clearSearch}/>
					: null}

				</div>
				<div className="cancel-button-container">
					<CustomButton variant="contained" title="Cancel" type="button" className="primary-button" onClick={this.props.closeSelectCity} />
				</div>
			</div>
		);
	}

}

export default SelectCitySearchInput;