import React, { Component } from "react";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import _ from "lodash";
import OtpInput from "react-otp-input";

import * as LOG_EVENT from "../../utils/analytics/index";
import WelcomeSelectCountry from "../register/welcome-continue/Welcome-select-country";
import WelcomeContinueButton from "../register/welcome-continue/Welcome-continue-button";
import CheckCodeRequestNew from "../register/CheckCode/CheckCodeRequestNew";

import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL, ABOUT_US_URL, MYKUYA_URL } from "../../utils/constants/urls";

import {
	setCountryCodeData,
	requestVerificationCode,
	setIntervalToEnterOtp,
	checkVerificationCode,
	onClearIntervalCheckCodeRequestNumber
} from "../../utils/redux/actions/registerActions";
import {
	phoneNumberHandlerAction,
	registerPhoneNumberValidUnSet,
	registerChangeCountrySelectedDispatch,
} from "../../utils/redux/actions/registerActions/registerChangeState";
import { wrongVerificationCode, enteringVerificationCode } from "../../utils/redux/actions/commonActions/commonChangeState";
import {localStorageSetCountryFlag} from "../../utils/functions/local-storage";
import {ReactComponent as BackIcon} from "../../v5-icons/back-button.svg";
import saripayLogo from "../../assests/icons/saripondo-logo-black.svg";
import DarkLoading from "../ui-elements/Loading/DarkLoading";
import { isSaripay } from "../../utils/functions/check-saripondo";
class LogInBottomSheet extends Component {
	constructor(props) {
		super(props);

		this.state = {
			focus: false,
			showCheckCod: false,
			otp: "",
			numInputs: 6,
			isInputNum: false,
			isDisabled: true,
			error: false,
			errorMessage: null,
		};
		this.welcomeSelectCountryRef = React.createRef();
		this.changeCountryHandler = this.changeCountryHandler.bind(this);
	}

	componentDidMount() {
		localStorage.removeItem("countryFlag");
		if (_.isEmpty(this.props.countries)) this.props.dispatch(setCountryCodeData());
	}

	changeCountryHandler(e) {
		this.welcomeSelectCountryRef.current.blur();
		this.props.dispatch(registerChangeCountrySelectedDispatch(this.props.countries[parseInt(e.target.value)]));
	}

	onFocus = (e) => {
		this.setState((prevProps) => ({
			focus: !prevProps.focus,
		}));
		if (e.target.parentNode.classList.contains("focused")) {
			e.target.parentNode.classList.remove("focused");
		} else {
			e.target.parentNode.classList.add("focused");
		}
	};

	phoneNumberHandler(e) {
		const { value } = e.target;
		if (value.length) {
			this.props.dispatch(phoneNumberHandlerAction(e.target.value.split(" ").join("")));
			this.setState(() => ({
				isDisabled: false,
				error: false,
			}));
		} else {
			this.props.dispatch(registerPhoneNumberValidUnSet());
			this.setState(() => ({
				isDisabled: true,
				error: false,
			}));
		}
	}

	isNumberRegular = (number) => {
		if (this.props.countrySelected.code === "+63") {
			return /^(9)\d{9}$/.test(parseInt(number.split(" ").join("")));
		} else {
			return /([0-9]{10})$/.test(parseInt(number.split(" ").join("")));
		}
	};

	onContinue = (e) => {
		if (this.isNumberRegular(this.props.number)) {
			localStorageSetCountryFlag(this.props.countrySelected.icon);
			this.props.dispatch(requestVerificationCode(false)).then((res) => {
				if (res.succeed) {
					this.props.dispatch(setIntervalToEnterOtp());
					this.props.dispatch(enteringVerificationCode(true));
					this.setState(() => ({
						showCheckCod: true,
						error: false,
						errorMessage: null,
					}));
				} else {
					this.setState(() => ({
						errorMessage: res.result,
						error: true,
					}));
				}
			});
		} else {
			this.setState(() => ({
				error: true,
				errorMessage: null,
			}));
		}
	};

	checkCodeHandler(otp) {
		this.setState({ otp: otp });
		this.props.dispatch(checkVerificationCode(otp));
	}

	onRequestNewCode = () => {
		localStorageSetCountryFlag(this.props.countrySelected.icon);
		LOG_EVENT.logEvent(LOG_EVENT.RESEND_CODE_REQUESTED_CWA, {isCall: false});
		// this.props.dispatch(requestVerificationCode(false)).then(() => this.props.dispatch(setIntervalToEnterOtp()));
		this.props.dispatch(setIntervalToEnterOtp());
		this.props.dispatch(requestVerificationCode(false));

	};

	onRequestNewCodeWithCall = () => {
		localStorageSetCountryFlag(this.props.countrySelected.icon);
		LOG_EVENT.logEvent(LOG_EVENT.RESEND_CODE_REQUESTED_CWA, {isCall: true});
		this.props.dispatch(setIntervalToEnterOtp());
		this.props.dispatch(requestVerificationCode(true));
		// this.props.dispatch(requestVerificationCode(true))
		// 	.then(() => this.props.dispatch(setIntervalToEnterOtp()));
	}

	backToEnterNumber = () => {
		this.setState(() => ({
			showCheckCod: false,
			otp: "",
		}));
		this.props.dispatch(enteringVerificationCode(false));
		this.props.dispatch(wrongVerificationCode(false));
		this.props.dispatch(onClearIntervalCheckCodeRequestNumber());
		localStorage.removeItem("countryFlag");
		this.props.dispatch(setCountryCodeData());
	};

	render() {
		const { otp, numInputs, isInputNum } = this.state;
		const phone = this.props.countrySelected.code + this.props.number;
		return !this.state.showCheckCod ? (
			<div className="login-bottom-sheet-container welcome">
				<img src={saripayLogo} className="welcome-img" alt="mykuya-logo" />
				<h2 className="welcome-title">Welcome to { isSaripay() ? "SariPondo" : "mykuya" } app</h2>
				<p className="welcome-subtitle">Enter your mobile number to continue</p>
				<div className="enter-phone-number-container">
					<div className="enter-phone-number">
						<div className="country-code">
							<WelcomeSelectCountry
								welcomeSelectCountryRef={this.welcomeSelectCountryRef}
								onChange={this.changeCountryHandler}
								options={this.props.countries}
							/>
						</div>
						<div className="phone-number" ref={(r) => (this.inputWrapper = r)}>
							<div className="form-group-container phone-number-container">
								<label>Mobile Number</label>
								<InputMask
									onBlur={this.onFocus}
									onFocus={this.onFocus}
									onChange={(e) => this.phoneNumberHandler(e)}
									maskChar=""
									className="Welcome-input"
									mask="999 999 9999"
									placeholder="9XX XXX XXXX"
								/>
							</div>
						</div>
					</div>
					{this.state.error ? (
						<p className="error">
							{!_.isNull(this.state.errorMessage) ? this.state.errorMessage : "Phone number must start with 9 and have 10 digits"}
						</p>
					) : null}
				</div>
				<div className="continue-auth">
					<div className="Welcome-continue-button-container">
						<WelcomeContinueButton
							disabled={this.state.isDisabled}
							onClick={this.onContinue}
							tryAgain={this.props.postPhoneNumberHasError}
							loading={this.props.verificationLoading}
						/>
					</div>
				</div>
				<div className="welcome-agree-container">
					<p className="welcome-agree">
						<span>SariPondo</span> is owned & operated by Global Noble International Corporation (GNIC).
					</p>
					<p className="welcome-agree">
						This application runs on{" "}
						<a className="welcome-agree-terms" href={MYKUYA_URL} target="_blank" rel="noopener noreferrer">mykuya® platform.</a>{" "}
						By signing up, you agree to mykuya's{" "}
						<a className="welcome-agree-terms" target="_blank" rel="noopener noreferrer" href={TERMS_AND_CONDITIONS_URL}>
							Terms of Use
						</a>{" "}
						&
						<a className="welcome-agree-policy" href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
							Privacy Policy
						</a>
					</p>
				</div>
			</div>
		) : (
			<div className="login-bottom-sheet-container check-code">
				<div className="enter-code-description">
					<BackIcon className="back-icon" onClick={() => this.backToEnterNumber()} />
					<p>Enter the 6-digit code sent to:</p>
					<p className="phone-number">{phone}</p>
				</div>
				<div className="enter-code-inputs">
					{this.props.enterVerificationCodeLoading ? (
						<div className="verification-loading">
							<DarkLoading />
						</div>
					) : (
						<OtpInput
							inputStyle="signup-input"
							containerStyle={this.props.common.isWrongVerificationCode ? "signup-input-conntainer error" : "signup-input-conntainer"}
							focusStyle="focused"
							numInputs={numInputs}
							errorStyle="error"
							onChange={(otp) => this.checkCodeHandler(otp)}
							isInputNum={isInputNum}
							shouldAutoFocus
							value={otp}
							autoFocus
						/>
					)}
					{this.props.common.isWrongVerificationCode ? <p className="error">Invalid code</p> : null}
				</div>

				<CheckCodeRequestNew onRequestNewCode={this.onRequestNewCode} onRequestNewCodeWithCall={this.onRequestNewCodeWithCall} />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
    ...state.register,
	common: state.common
});

export default connect(mapStateToProps)(LogInBottomSheet);
