import React, { Component, Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '@adyen/adyen-web/dist/adyen.css';
import "./App.css";
import "../../shared-style/main.scss";

import { appAuthCheck, appCheckRotation, appCheckTwa, appDetectTWA } from "../../utils/redux/actions/commonActions";
import { appDetectDesktopUser } from "../../utils/redux/actions/commonActions/commonChangeState";

import WelcomeLoadingScreen from "../loading-screen/LoadingScreen";
import NotFound from "../not-found/not-found";
import LoadingScreen from "../../components/ui-elements/loading-screen/loading-screen";
import AuthRequired from "./auth-required";
import LoadingScreenKuya from "../loading-screen/loading-screen-kuya";
import Notification from "../notification/notification";
import InternetConnection from "../internet-connection/internet-connection";
import * as LOG_EVENT from '../../utils/analytics';
import SetLocationWithMap  from "../LocationSearch/LocationSearchMap/SetLocationWithMap";

import Home from "../HomePage/Home/Home";
import Tutorial from "../tutorial/tutorial";
import Booking from "../Booking/Booking";
import News from "../News/News";
import NewsDetail from "../News/NewsDetail/NewsDetail";
import Account from "../Account/Account";
import Profile from "../Account/Profile/Profile";
import Refer from "../Account/refer/refer";
import JobInformation from "../job-history/JobInformation/JobInformation";
import JobReceipt from "../job-history/JobReceipt/JobReceipt";
import OpenJobInfo from "../job-history/Jobs/OpenJobInfo";
import JobChatHistory from "../job-history/JobChatHistory/JobChatHistory";
import JobChatHistoryMenuPage from "../job-history/JobChatHistory/JobChatHistoryMenuPage/JobChatHistoryMenuPage";
import LocationSearch from "../LocationSearch/LocationSearch";
import LocationSearchBooking from "../LocationSearchBooking/LocationSearchBooking";
import LocationSearchMap from "../LocationSearch/LocationSearchMap/LocationSearchMap";
import BillingInformation from "../Account/Billing/BillingInformation/BillingInformation";
import BillingAddCardV2 from "../Account/Billing/BillingAddCardV2/BillingAddCardV2";
import BillingGCash from "../Account/Billing/BillingInformation/BillingGCash/BillingGCash";
import LocationSearchBookingAddMoreStop from "../LocationSearchBooking/location-search-booking-add-more-stop/location-search-booking-add-more-stop";
import LocationSearchBookingAddSearch from "../LocationSearchBooking/location-search-booking-add-search/location-search-booking-add-search";
import JobMatching from "../job-history/JobMatching/JobMatching";
import TutorialSafari from "../tutorial/tutorial-safari";
import TutorialChrome from "../tutorial/tutorial-chrome";
import TutorialFirefox from "../tutorial/tutorial-firefox";
import SavedPlaces from "../SavedPlaces/SavedPlaces";
// import SavedPlacesSearch from "../SavedPlaces/SavedPlacesSearch/SavedPlacesSearch";
import SavedPlacesAddress from "../SavedPlaces/SavedPlacesAddress/SavedPlacesAddress";
import SavedPlacesUpdateAddress from "../SavedPlaces/SavedPlacesUpdateAddress/SavedPlacesUpdateAddress";
import HomeSupport from "../support/HomeSupport";
import Support from "../support/support";
import AboutMyKuya from "../AboutMyKuya/AboutMyKuya";
import BookingNote from "../Booking/BookingNote/BookingNote";
import ReviewBooking from "../Booking/ReviewBooking/ReviewBooking";
import HomeSubCategories from "../HomePage/HomeSubCategories/HomeSubCategories";
import VideoChat from "../VideoChat/VideoChat";
import MyJobsList from "../MyJobsList/MyJobsList";
import HistoryTransactions from "../SendRequestMK/HistoryTransactions/HistoryTransactions";
import AddTransactions from "../SendRequestMK/AddTransaction/AddTransaction";
import PartnerLiveLocation from "../PartnerLiveLocation/PartnerLiveLocation";
import Welcome from "../register/welcome";
import { Tabbar } from "../Tabbar/Tabbar";
import MymallReviewBooking from "../Booking/MymallReviewBooking/MymallReviewBooking";
import BrowserNotSupported from "../BrowserNotSupported/BrowserNotSupported";

import { VERSION_CWA_NUMBER } from "../../utils/constants/index";
import { localStorageSetVersion } from "../../utils/functions/local-storage";
import { CustomizedSnackbar } from "../CustomizedSnackbar/CustomizedSnackbar";
import { isChrome, isIOS, isSamsungBrowser } from "../../utils/functions/check-browser";
import BillingAddCardV3 from "../Account/Billing/BillingAddCardV3/BillingAddCardV3";
const tabsRoute = ['/home', '/jobs', '/account'];

class App extends Component {
	constructor(props) {
		super(props);
		this.props.dispatch(appAuthCheck());
		this.props.dispatch(appDetectDesktopUser());
		this.props.dispatch(appCheckTwa());
		this.props.dispatch(appDetectTWA());
	}
	
	componentDidMount() {
		const zendeskTimer = setTimeout(() => {
			if (typeof window.zE !== 'undefined') {
				window.zE('messenger', 'hide');
			}
			clearTimeout(zendeskTimer)
		}, 1500)

		this.props.dispatch(appCheckRotation());
		LOG_EVENT.logEvent(LOG_EVENT.APP_LAUNCHED_CWA, { value: 1.00, currency: 'PHP' });
		localStorageSetVersion(VERSION_CWA_NUMBER);
	}

	fallback = () => {
		// return <LoadingScreen />;
		// return this.props.router.location.pathname === "/home" && !this.props.goHomeFromOtherPage && !this.props.clickOneOnBottomBar ? (
		// 	<LoadingScreenKuya />
		// ) : (
		// 	<LoadingScreen />
		// );
	};

	render() {
		return (
			<div className="App fixed-wrapper fixed-wrapper__main">
				{this.props.loading ? <LoadingScreen /> : ""}
				{this.props.loadingKuya ? <LoadingScreenKuya /> : ""}
				<Fragment>
					<Switch>
						<Route exact path="/" component={WelcomeLoadingScreen} />
						<Route exact path="/desktop" component={WelcomeLoadingScreen} />
						<Route exact path="/map" render={(props) => <SetLocationWithMap {...props} />} />
						<Route exact path="/welcome" render={(props) => <Welcome {...props} />} />
						<Route exact path="/not-support" render={(props) => <BrowserNotSupported {...props} />} />
						{/* <Route exact path="/register" render={(props) => <AuthRequired auth={this.props.auth} orRender={<RegisterForm {...props} />} />}
						/> */}
						<Route exact path="/home" render={(props) => <AuthRequired auth={this.props.auth} orRender={<Home {...props} />} />} />
						<Route
							exact
							path="/about-mykuya"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<AboutMyKuya {...props} />} />}
						/>
						<Route
							exact
							path="/booking/:id"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<Booking {...props} />} />}
						/>
						<Route
							exact
							path="/subcategories/:productsId"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<HomeSubCategories {...props} />} />}
						/>
						{/* <Route exact path="/booking/:id/map" render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
						/> */}
						<Route
							exact
							path="/booking/:id/map"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<SetLocationWithMap {...props} />} />}
						/>
						<Route
							exact
							path="/booking/:id/note"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<BookingNote {...props} />} />}
						/>
						<Route
							exact
							path="/booking/:id/review"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<ReviewBooking {...props} />} />}
						/>
						<Route
							exact
							path="/booking/:id/review-mymall"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<MymallReviewBooking {...props} />} />}
						/>
						<Route
							exact
							path="/search"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearch {...props} />} />}
						/>
						<Route
							exact
							path="/search/map"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
						/>
						<Route
							exact
							path="/search/:id/booking"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchBooking {...props} />} />}
						/>
						<Route
							exact
							path="/search/:id/booking/map"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
						/>
						<Route
							exact
							path="/search/:id/booking/add"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchBookingAddMoreStop {...props} />} />}
						/>
						<Route
							exact
							path="/search/:id/booking/add/:index"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchBookingAddSearch {...props} />} />}
						/>
						<Route
							exact
							path="/search/:id/booking/add/:index/map"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
						/>
						<Route
							exact
							path="/saved-places"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<SavedPlaces {...props} />} />}
						/>
						{/* <Route exact path="/saved-places/search" render={(props) => <AuthRequired auth={this.props.auth} orRender={<SavedPlacesSearch {...props} />} />}
						/> */}
						<Route
							exact
							path="/saved-places/search"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<SetLocationWithMap {...props} />} />}
						/>
						<Route
							exact
							path="/saved-places/search/map"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<LocationSearchMap {...props} />} />}
						/>
						<Route
							exact
							path="/saved-places/search/add"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<SavedPlacesAddress {...props} />} />}
						/>
						<Route
							exact
							path="/saved-places/search/edit"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<SavedPlacesUpdateAddress {...props} />} />}
						/>
						<Route exact path="/jobs" render={(props) => <AuthRequired auth={this.props.auth} orRender={<MyJobsList {...props} />} />} />
						<Route
							exact
							path="/jobs/:id"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobInformation {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/matching"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobMatching {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/receipt"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobReceipt {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/chat"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobChatHistory {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/chat/menu"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<JobChatHistoryMenuPage {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/chat/history-transactions"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<HistoryTransactions {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/chat/add-transaction"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<AddTransactions {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/partner-location"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<PartnerLiveLocation {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/chat/voip"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<VideoChat {...props} />} />}
						/>
						<Route
							exact
							path="/jobs/:id/open-job"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<OpenJobInfo {...props} />} />}
						/>
						<Route exact path="/news" render={(props) => <AuthRequired auth={this.props.auth} orRender={<News {...props} />} />} />
						<Route
							exact
							path="/news/:id"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<NewsDetail {...props} />} />}
						/>
						<Route exact path="/account" render={(props) => <AuthRequired auth={this.props.auth} orRender={<Account {...props} />} />} />
						<Route
							exact
							path="/account/profile"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<Profile {...props} />} />}
						/>
						<Route
							exact
							path="/account/refer"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<Refer {...props} />} />}
						/>
						<Route
							exact
							path="/account/billing"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<BillingInformation {...props} />} />}
						/>
						<Route
							exact
							path="/account/billing/add"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<BillingAddCardV2 {...props} />} />}
						/>
						<Route
							exact
							path="/account/billing/add-gcash"
							render={(props) => <AuthRequired auth={this.props.auth} orRender={<BillingGCash {...props} />} />}
						/>
						<Route exact path="/home-support" render={(props) => <HomeSupport {...props} />} />
						<Route exact path="/support" render={(props) => <Support {...props} />} />
						<Route exact path="/tutorial" render={(props) => <Tutorial {...props} />} />
						<Route exact path="/notification" render={(props) => <Notification {...props} />} />
						<Route exact path="/tutorial/safari" render={(props) => <TutorialSafari {...props} />} />
						<Route exact path="/tutorial/chrome" render={(props) => <TutorialChrome {...props} />} />
						<Route exact path="/tutorial/firefox" render={(props) => <TutorialFirefox {...props} />} />
						<Route path="*" component={NotFound} />
					</Switch>
					{tabsRoute.includes(this.props.history.location.pathname) && <>
						{/*{((!isChrome() && !isSamsungBrowser()) || isIOS()) &&*/}
						{/*	!this.props.booking?.requestPlacedModal && <CustomizedSnackbar />}*/}
						<Tabbar />
					</>}
				</Fragment>
				<ToastContainer />
				{this.props.isInternetDisconnected ? <InternetConnection /> : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.common,
	booking: state.booking,
	router: state.router,
});

export default connect(mapStateToProps)(withRouter(App));
