import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { datadogRum } from "@datadog/browser-rum";

import './index.css';
import App from './components/app/App';
import { VERSION_CWA_NUMBER } from "./utils/constants/index";
// import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { store, history } from './utils/redux/store';
import firebase from "./utils/functions/firebase-initialize";
// if (process.env.NODE_ENV === 'production') {
    Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_SENTRY_ENV,
		release: VERSION_CWA_NUMBER
	});
// }

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>,
	document.getElementById("root"),
);

datadogRum.init({
	applicationId: process.env.REACT_APP_APPLICATION_ID_DATA_DOG,
	clientToken: process.env.REACT_APP_CLIENT_TOKEN_DATA_DOG,
	site: "datadoghq.com",
	service: "cwa",
	env: process.env.REACT_APP_SENTRY_ENV,
	// Specify a version number to identify the deployed version of your application in Datadog
	version: VERSION_CWA_NUMBER,
	sampleRate: 100,
	trackInteractions: true,
	defaultPrivacyLevel: "mask-user-input",
	allowedTracingOrigins: [process.env.REACT_APP_DATA_DOG_ENV],
});

// datadogRum.startSessionReplayRecording();

navigator.serviceWorker.onmessage = (event) => {
	if (event?.data?.origin === "chat-menu") {
		window.history.back();
	}
	if (event?.data?.origin === "mykuya") {
		window.location.href = `${window.location.host.includes("localhost") ? 'http://' : 'https://'}
		${window.location.host}/${event?.data?.destination}`
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// let config = {
//     onUpdate: (registration) => {
//         toast.info(`New Update available! Reload the App to see the latest juicy changes.`, {
//             position: "bottom-center",
//             autoClose: 10000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             onClose: () => {
//                 serviceWorker.unregisterServiceWorker();
//                 window.location.reload();
//             }
//         });
//     },
// }

// serviceWorker.register(config);